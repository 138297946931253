import React from 'react';
import waves from "../../../../assets/images/features-wave.png";
import { Col, Container, Row } from 'react-bootstrap';
import arIcon from "../../../../assets/icons/ar.png";
import gameIcon from "../../../../assets/icons/gaming.png";
import infrastructureIcon from "../../../../assets/icons/infrastructure.png";
import vrIcon from "../../../../assets/icons/vr.png";
import styles from "./Features.module.scss";
import SectionTitle from '../../../common/SectionTitle/SectionTitle';
import { CONTENT } from '../../../../utils/constant';

const Features = () => {
    let features = [
        {
            title: "Gaming",
            icon: [gameIcon],
            text: CONTENT.FEAUTRES_GAME,
        },
        {
            title: "AR/VR",
            icon: [arIcon, vrIcon],
            text: CONTENT.FEATURES_AR_VR,
        },
        {
            title: "Platform",
            icon: [infrastructureIcon],
            // subTitle: "Our First Release",
            text: CONTENT.FEATURES_PLATFORM,
        },
    ]
    return (
        <>
            <section id="features" className={styles.features}>
                <div
                    className={styles.waves}
                >
                    <img src={waves} alt="" />
                    <img src={waves} alt="" />
                    <img src={waves} alt="" />
                    <img src={waves} alt="" />
                    <img src={waves} alt="" />
                </div>
                <Container>
                    <SectionTitle className={"text-center"}>Features</SectionTitle>
                    <Row className='justify-content-center'>
                        {
                            features.map(item => (
                                <Col key={item.title} xl={4} lg={6}>
                                    <div className={styles.feature}>
                                        <div>
                                            <div className={styles.feature_img}>
                                                {
                                                    item.icon.map(icon => <div key={icon}><img src={icon} alt="" /></div>)
                                                }
                                            </div>
                                            <h3>{item.title}</h3>
                                            {item.subTitle && <h4>{item.subTitle}</h4>}
                                            <p>{item.text}</p>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Features
