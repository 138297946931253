import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from "./Header.module.scss";
import { Link as Navlink } from 'react-scroll';
import logo from "../../../assets/logo/big-logo.png";
import { SOCIAL_LINKS } from '../../../utils/constant';
import { TelegramIcon, TwitterIcon } from '../../../assets/icons/icons';

const Header = () => {
    const [active, setActive] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const handleActive = () => {
        document.body.clientWidth < 991 && setActive(!active);
    };
    const nav = [
        {
            to: "home",
            label: "Home"
        },
        {
            to: "ecosystem",
            label: "Ecosystem"
        },
        {
            to: "features",
            label: "Features"
        },
        {
            to: "games",
            label: "Games"
        },
        {
            to: "roadmap",
            label: "Roadmap"
        },
        {
            to: "tokenomics",
            label: "Tokenomics"
        },
    ];

    useEffect(() => {
        setScrolled(window.scrollY > 0);
        window.onscroll = () => {
            setScrolled(window.scrollY > 0);
        }
    }, [])

    return (
        <>
            <header className={`${styles.header} ${scrolled ? styles.active : ""}`}>
                <Container>
                    <div className={styles.in}>
                        <Link to="/" className={styles.logo}><img src={logo} alt="logo" /></Link>
                        <div className={styles.nav}>
                            <div className={`${styles.sidebar} ${active ? styles.active : ""}`}>
                                <ul>
                                    {
                                        nav.map(item => (
                                            <li key={item.to}>
                                                <Navlink
                                                    to={item.to}
                                                    activeClass={styles.active}
                                                    spy={true}
                                                    smooth={true}
                                                    duration={100}
                                                    offset={item.offset || -60}
                                                    onClick={handleActive}
                                                >
                                                    <span>{item.label}</span>
                                                </Navlink>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className={styles.social_media}>
                                <Link to={SOCIAL_LINKS.TELEGRAM} target="_blank" rel="noreferrer"><TelegramIcon /></Link>
                                {/* <Link to={SOCIAL_LINKS.DISCORD}><DiscordIcon /></Link> */}
                                <Link to={SOCIAL_LINKS.TWITTER} target="_blank" rel="noreferrer"><TwitterIcon /></Link>
                            </div>
                            <button onClick={handleActive} className={`${styles.toggle} ${active ? styles.active : ""} d-lg-none`}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </Container>
            </header>
        </>
    )
}

export default Header
