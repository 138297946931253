import React from 'react'
import styles from "./Ecosystem.module.scss";
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from '../../../common/SectionTitle/SectionTitle';
import frame from "../../../../assets/images/monkey-frame.png"
import monkey from "../../../../assets/images/monkey.gif";
import { CONTENT } from '../../../../utils/constant';

const Ecosystem = () => {
    return (
        <>
            <section id="ecosystem" className={styles.ecosystem}>
                <Container>
                    <div className={styles.in}>
                        <Row className="align-items-center">
                            <Col lg={6}>
                                <SectionTitle className={"text-lg-start text-center"}>Aper Ecosystem</SectionTitle>
                                <p className={styles.text}>
                                    {CONTENT.ECOSYSTEM_TEXT_1} <br /> <br />
                                    {CONTENT.ECOSYSTEM_TEXT_2}
                                </p>
                            </Col>
                            <Col
                                lg={6}>
                                <div className={styles.ecosystem_img}>
                                    <img src={monkey} className={styles.monkey} alt="monkey" />
                                    <img src={frame} alt="frame" className={styles.frame} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Ecosystem
