import { ArcElement, Chart as ChartJS, Tooltip } from "chart.js";
import styles from "./Tokenomics.module.scss";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "../../../common/SectionTitle/SectionTitle";
ChartJS.register(ArcElement, Tooltip);

const Tokenomics = () => {
  const tokenomics = [
    {
      title: "Token Sale",
      amount: 40,
      color: "rgb(134, 204, 255)",
    },
    {
      title: "Community Rewards",
      amount: 32,
      color: "rgb(14, 203, 129)",
    },
    {
      title: "CEX",
      amount: 5,
      color: "rgb(247, 116, 50)",
    },
    {
      title: "Marketing",
      amount: 5,
      color: "rgb(246, 70, 93)",
    },
    {
      title: "Liquidity",
      amount: 10,
      color: "rgb(168, 85, 247)",
    },
    {
      title: "Team",
      amount: 8,
      color: "rgb(37, 99, 235)",
    },
  ];

  const data = {
    labels: ["Token Sale", "Community Rewards", "CEX", "Marketing", "Liquidity", "Team"],
    datasets: [
      {
        data: [40, 32, 5, 5, 10, 8],
        backgroundColor: [
          "rgb(134, 204, 255)",
          "rgb(14, 203, 129)",
          "rgb(247, 116, 50)",
          "rgb(246, 70, 93)",
          "rgb(168, 85, 247)",
          "rgb(37, 99, 235)",
        ],
        cutout: "70%",
        borderWidth: 0,
        // borderRadius: document.body.clientWidth > 991 ? 20 : 10,
        borderRadius: 14,
      },
    ],
  };
  const options = {
    title: {
      display: true,
      text: "Doughnut Chart",
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
            console.log('tooltipItem:', tooltipItem);
            console.log('data:', data);
            return `${data.labels[tooltipItem.index]}: ${data.datasets[0].data[tooltipItem.index]}%`;
        }
      },
    },
  };
  return (
    <section id="tokenomics" className={styles.tokenomics}>
      <Container>
        <SectionTitle className={styles.title}>Tokenomics</SectionTitle>
        <Row className="align-items-center">
          <Col md={6}>
            <div className={styles.chart}>
              <Doughnut data={data} options={options} />
              <h3>APERVerse</h3>
            </div>
          </Col>
          <Col md={6}>
            <ul>
              {tokenomics.map((item, key) => (
                <li key={key}>
                  <div>
                    <span
                      className={styles.color_tile}
                      style={{ backgroundColor: item.color }}
                    ></span>
                    <h4>{item.title}</h4>
                    <p>{item.amount}%</p>
                  </div>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Tokenomics;
