import { animate, stagger } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import bannerBg from "../../../../assets/videos/banner-bg.mp4";
import styles from "./Banner.module.scss";
import { CONTENT } from '../../../../utils/constant';

const Banner = () => {
    const ref = useRef([]);
    useEffect(() => {
        animate(ref.current,
            {
                // scale: [4, 1],
                opacity: [0, 1],
                filter: ["blur(1rem)", "blur(0rem)"],
                y: [100, 0],
            },
            {
                duration: 0.4,
                delay: stagger(0.05),
            })
    }, [])
    return (
        <>
            <section id="home" className={styles.banner}>
                <video controls={false} muted loop autoPlay playsInline className={styles.banner_bg}>
                    <source src={bannerBg} />
                </video>
                <Container>
                    <div className={styles.in}>
                        <h1>{"APERVerse".split("").map((item, index) => {
                            return <div ref={ele => ref.current[index] = ele} key={item + index}>{item}</div>
                        })}</h1>
                        {/* <p>Experience the Fusion of Augmented Reality, Games, NFTs and Infrastructure to onboard millions of gamers</p> */}
                        <p>{CONTENT.BANNER_TEXT}</p>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Banner
