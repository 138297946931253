import React from 'react'
import styles from "./SectionTitle.module.scss";
import browserDetect from 'browser-detect';

const SectionTitle = ({ children, className }) => {
    const result = browserDetect();
    return (
        <>
            <div className={`${styles.title} ${className || ''}`}>
                <h2 className={result.name === "chrome" || result.name === "firefox" ? styles.text_shadow : ""}>{children}</h2>
            </div>
        </>
    )
}

export default SectionTitle
