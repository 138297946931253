export const SOCIAL_LINKS = {
    TELEGRAM: "",
    DOCS: "",
    TWITTER: "https://twitter.com/aperversear",
    DISCORD: "",
}

export const CONTENT = {
    BANNER_TEXT: "Experience the Fusion of Augmented Reality, Games, NFTs and next generation easy to use WEB 3 games  platform",
    ECOSYSTEM_TEXT_1: "Your gateway to WEB 3 gaming innovation. Beyond in-house games, we offer a Platform as a Service, envisioning ourselves as the Epic Games of the WEB 3",
    ECOSYSTEM_TEXT_2: "At Aperverse, our mission is to bring you immersive, pioneering, and enjoyable experiences that seamlessly blend with cutting-edge technology. Play fun in house games, bring your memes/NFTs to life, get our SDK to launch your game in WEB 3..",
    FEAUTRES_GAME: "Immerse yourself in our exclusive on-chain games. Presently, we're crafting an exhilarating Hoverboard and Motorbike Racing game featuring Hambe—an adventurous ape character. Stay tuned for the introduction of additional captivating characters, promising an ever-expanding gaming universe. Get ready for a gaming experience like no other!",
    FEATURES_AR_VR: "Coming soon to iOS, Android, and Vision Pro, our AR app allows you to bring your NFT memes to life effortlessly. Stay tuned as we have more in store, with upcoming AR and VR games on the horizon. Get ready for an array of sports-themed adventures, including Soccer, Baseball, and Rugby.",
    FEATURES_PLATFORM: "Experience the power of WEB 3 gaming with our revolutionary no-code platform. With just a click of a button, you can launch captivating games, seamlessly convert XP points to native tokens, and introduce your very own gaming token. Our platform supports the creation and integration of NFTs, facilitates Airdrop campaigns, provides Play2Earn rewards, and offers easy access to Fiat on Ramp.",
    ROADMAP: {
        PHASE_1: ["APER AR MVP Development", "Website Live", "Token Sale", "Staking", "Aper AR Alpha Release (Invite Only)", "AR Memes App", "APER NFT Collection", "AR NFTs Integration"],
        PHASE_2: ["Aper AR Beta Release", "Mobile App Beta Release (iOS and Android)", "Aper Realm Basketball", "Aper Realm Baseball", "Aper Web3 Service Platform Alpha",],
        PHASE_3: ["Aper Realm Boxing", "Vision Pro App Development for AR games", "Aper Web3 Service Platform Beta", "New Game Titles", "Guild Development",],
    },
    APER_AR: "Introducing our inaugural release: an AR App where NFTs and Memes spring to life. Easily import NFTs or mint your memes as NFTs, bringing them seamlessly into the augmented digital world. Engage in AR games, epic battles, or watch your characters perform daring stunts on Motorbikes and Hoverboards for an immersive and entertaining experience.",
    APE_REALM: "ApeRealm will release series of Sports and Adventure games around 5 central characters forged with five elements of nature. We will release ApeRealm NFT collection in March 2023. Users will have opportunity to play vs AI or in PVP mode with opportunity to win prizes",
}