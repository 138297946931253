import React from 'react'
import ios from "../../../../assets/icons/app-store-badge.svg"
import android from "../../../../assets/icons/google-play-badge.svg"
import styles from "./Games.module.scss";
import SectionTitle from '../../../common/SectionTitle/SectionTitle';
import monkeys from "../../../../assets/images/monkey.png";
import { Container, Col, Row } from "react-bootstrap";
import vid from "../../../../assets/videos/phone-video.mp4";
import frame from "../../../../assets/images/phone-frame.png";
import { CONTENT } from '../../../../utils/constant';

const Games = () => {
    return (
        <>
            <section id="games" className={styles.games}>
                <Container>
                    <div className={styles.game}>
                        <Row className="align-items-center">
                            <Col lg={6} className="order-lg-1">
                                <div className={styles.mobile_video}>
                                    <video controls={false} loop muted autoPlay playsInline>
                                        <source src={vid} />
                                    </video>
                                    <img src={frame} alt="iphone frame" />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className={styles.games_text}>
                                    <SectionTitle>Aper AR</SectionTitle>
                                    {/* <p>Introducing our inaugural release: an AR App where NFTs and Memes spring to life. Easily import NFTs or mint your memes as NFTs, bringing them seamlessly into the augmented digital world. Engage in AR games, epic battles, or watch your characters perform daring stunts on Motorbikes and Hoverboards for an immersive and entertaining experience.</p> */}
                                    <p>{CONTENT.APER_AR}</p>
                                    <div className={styles.availiblity}>
                                        <button className={styles.coming__soon}>
                                            <img src={android} alt="android" />
                                        </button>
                                        <button className={styles.coming__soon}>
                                            <img src={ios} alt="ios " /></button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className={styles.game}>
                        <Row className="align-items-center">
                            <Col lg={6}>
                                <div className={styles.monkeys}>
                                    <img src={monkeys} alt="monkeys" />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className={styles.games_text}>
                                    <SectionTitle>Aper Realm</SectionTitle>
                                    <p>{CONTENT.APE_REALM}</p>
                                    {/* <p>ApeRealm will release series of Sports and Adventure games around 5 central characters forged with five elements of nature. We will release ApeRealm NFT collection in March 2023. Users will have opportunity to play vs AI or in PVP mode with opportunity to win prizes.</p> */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Games
