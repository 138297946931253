import React from 'react'
import styles from "./Roadmap.module.scss";
import { Container } from 'react-bootstrap';
import SectionTitle from '../../../common/SectionTitle/SectionTitle';
import { CONTENT } from '../../../../utils/constant';

const Roadmap = () => {
    return (
        <section id="roadmap" className={styles.roadmap}>
            <Container fluid>
                <SectionTitle className={"text-center"}>Roadmap</SectionTitle>
                <div className={styles.roadmap_in}>
                    <div className={styles.roadmap_box}>
                        <div className={styles.roadmap_box_in}>
                            <div className={styles.box}>
                                <h3>Phase 1</h3>
                                <ul>
                                    {
                                        CONTENT.ROADMAP.PHASE_1.map(item => (
                                            <li key={item}>{item}</li>
                                        ))
                                    }
                                    {/* <li>Aper Staking</li>
                                    <li>AR Memes App</li>
                                    <li>AR NFTs</li>
                                    <li>APER NFT Collection</li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={styles.roadmap_box}>
                        <div className={styles.roadmap_box_in}>
                            <div className={styles.box}>
                                <h3>Phase 2</h3>
                                <ul>
                                    {
                                        CONTENT.ROADMAP.PHASE_2.map(item => (
                                            <li key={item}>{item}</li>
                                        ))
                                    }
                                    {/* <li>Mobile App Release (iOS and Android)</li>
                                    <li>Aper Racing Game Release</li>
                                    <li>Aper Hoverboard Game</li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={styles.roadmap_box}>
                        <div className={styles.roadmap_box_in}>
                            <div className={styles.box}>
                                <h3>Phase 3</h3>
                                <ul>
                                    {
                                        CONTENT.ROADMAP.PHASE_3.map(item => (
                                            <li key={item}>{item}</li>
                                        ))
                                    }
                                    {/* <li>Mobile App Updates</li>
                                    <li>More Games</li>
                                    <li>AR NFTs</li>
                                    <li>Guild Development</li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Roadmap
