import React from 'react'
import Header from '../common/Header/Header'
import Footer from '../common/Footer/Footer'
import styles from "./Layout.module.scss";
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <>
            <main className={styles.layout}>
                <Header />
                <Outlet />
                <Footer />
            </main>
        </>
    )
}

export default Layout
