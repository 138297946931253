import React from 'react'
import logo from "../../../assets/logo/big-logo.png";
import styles from "./Footer.module.scss";
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SOCIAL_LINKS } from '../../../utils/constant.js';
import { TelegramIcon, TwitterIcon } from '../../../assets/icons/icons';

const Footer = () => {
    return (
        <>
            <footer className={styles.footer}>
                <Container>
                    <div className={styles.footer_in}>
                        <p className={styles.copyright_txt}>© {(new Date()).getFullYear()} APERVerse</p>
                        <Link to="/" className={styles.footer_logo}>
                            <img src={logo} alt="aperverse" />
                        </Link>
                        <ul>
                            <li>
                                <Link target="_blank" rel="noreferrer" to={SOCIAL_LINKS.TELEGRAM}><TelegramIcon /></Link>
                            </li>
                            {/* <li>
                                <Link to={SOCIAL_LINKS.DISCORD}><DiscordIcon /></Link>
                            </li> */}
                            <li>
                                <Link target="_blank" rel="noreferrer" to={SOCIAL_LINKS.TWITTER}><TwitterIcon /></Link>
                            </li>
                        </ul>
                    </div>
                </Container>
            </footer>
        </>
    )
}

export default Footer
