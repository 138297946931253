import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./components/layouts/Layout";
import Home from "./components/pages/Home/Home";

function App() {
	const router = createBrowserRouter([
		{
			path: "/",
			element: <Layout />,
			children: [
				{
					index: true,
					element: <Home />,
				},
			],
		},
	]);
	return (
		<>
			<RouterProvider router={router} />
		</>
	);
}

export default App;
