import Banner from "./Banner/Banner"
import Ecosystem from "./Ecosystem/Ecosystem"
import Features from "./Features/Features"
import Games from "./Games/Games"
import Roadmap from "./Roadmap/Roadmap"
import Tokenomics from "./Tokenomics/Tokenomics"

const Home = () => {
    return (
        <>
            <Banner />
            <Ecosystem />
            <Features />
            <Games />
            <Roadmap />
            <Tokenomics />
        </>
    )
}

export default Home
